<template>
    <div class="logo">
      <div class="logo_font">实名认证</div>
      <div class="inputbox">
        <div class="title">证件类型</div>
        <div class="inputinbox2">
            <van-radio-group v-model="formData.idCardType" direction="horizontal" :disabled="!showAuth">
                <van-radio name="0">身份证</van-radio>
                <van-radio name="1">港澳通行证</van-radio>
            </van-radio-group>

        </div>
      </div>
      <div class="inputbox">
        <div class="title">真实姓名</div>
        <input
          class="inputinbox"
          v-model="formData.userName"
          :disabled="!showAuth"
          placeholder="请输入真实姓名"
        />
      </div>
      <div class="inputbox">
        <div class="title">证件号码</div>
        <input
          class="inputinbox"
          v-model="formData.idCardNo"
          :disabled="!showAuth"
          placeholder="请输入证件号"
        />
      </div>
      <div class="tips">注：登记实名制信息（与微信实名制认证信息一致）</div>
      <button v-preventReClick class="login_btn" @click="submit" v-if="showAuth">
        立即实名
      </button>
      
    </div>
  </template>
  <script>
  import { Notify } from "vant";
  export default {
    data() {
      return {
        showAuth:false,
        option1:[{ text: '身份证', value: 0 },{ text: '港澳通行证', value: 1 }],
    
        formData: {
            idCardType:'0',//0.身份证 1.港澳通行证
            userName: "",
            idCardNo:""
        },
      };
    },
    created() {
        this.$http.searchMerchantRealId().then((res) => {
          if (res.code == 200) {
            res.data.idCardType = res.data.idCardType+''
            this.formData = res.data
          } else {
            this.showAuth = true
          }
        });
    },
    methods: {
      //改变登录方式
      changetype() {
        this.logotype = !this.logotype;
      },
      //记住密码
      changerem() {
        this.rembermer = !this.rembermer;
      },
      //发送验证码
      sendyzm() {
        console.log(this.formData.mobile);
        if (this.formData.mobile) {
          this.$http.msgSend({ mobile: this.formData.mobile, msgType:13}).then((res) => {
            console.log(res);
            if (res.code == 200) {
              Notify({ type: "primary", message: res.msg });
            } else {
              Notify({ type: "primary", message: res.msg });
            }
          });
          this.yzmtype = 2;
          this.timer = setInterval(() => {
            this.num = this.num - 1;
            if (this.num <= 0) {
              clearInterval(this.timer);
              this.num = 60;
              this.yzmtype = 3;
            }
          }, 1000);
        } else {
          Notify({ type: "primary", message: "请先输入手机号" });
        }
      },
      //检测手机号
      phonejc() {
        console.log(this.formData);
        if (/^1[3-9]\d{9}$/.test(this.formData.mobile)) {
          console.log("jinlal");
        } else {
          this.formData.mobile = "";
          Notify({ type: "primary", message: "手机号格式错误" });
        }
      },
      //路由
      routers(path) {
        console.log("path", path);
        this.$router.push(path);
      },
      //实名
      submit() {
        if(!this.formData.userName || !this.formData.idCardNo){
            Notify({ type: "primary", message: "请输入姓名和证件号" });
            return
        }
        this.$http.registerMerchantRealId(this.formData)
            .then((res) => {
            console.log(res);
            if (res.code == 200) {
                this.$router.go(-1)
            } else {
                Notify({ type: "primary", message: res.msg });
            }
        });
  
      },
      
    },
  };
  </script>
  <style lang="scss" scoped>
  .logo {
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    overflow-y: auto;
    padding: 0 30px;
    padding-top: 64px;
    box-sizing: border-box;

    .logo_font {
      width: 315px;
      margin: 0 auto;
      margin-bottom: 35px;
      font-weight: 500;
      color: #333333;
      font-size: 24px;
    }
    .inputbox {
      width: 315px;
      margin: 0 auto;
      margin-bottom: 20px;
      height: 45px;
  
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .title{
        margin-right: 20px;
        font-size: 15px;
        font-weight: 450;

      }
      .inputinbox {
        flex: 1;
        height: 100%;
        background: rgba(0, 112, 251, 0.1);
        font-size: 16px;
        padding-left: 16px;
        box-sizing: border-box;
      }

      .inputinbox2{
        font-size: 16px;

      }
    }
    .tips{
        font-size: 13px;
        color: rgb(255, 68, 68);
    }
    .login_btn {
      margin: 0 auto;
      margin-top: 52px;
      width: 315px;
      height: 47px;
      background: #1890ff;
      border-radius: 24px;
      font-weight: 500;
      color: #ffffff;
      font-size: 16px;
      line-height: 47px;
      text-align: center;
    }
    .change_font {
      width: 315px;
      text-align: center;
      margin: 0 auto;
      margin-top: 32px;
      font-weight: 500;
      color: #0070fb;
      font-size: 14px;
    }
    .input_left {
      width: 191px;
      height: 100%;
      font-size: 16px;
      padding-left: 16px;
      box-sizing: border-box;
      background: rgba(0, 112, 251, 0.05);
    }
    .input_right {
      height: 100%;
      flex: 1;
      background: rgba(0, 112, 251, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      .yzm_send {
        width: 100%;
        border-left: 1px solid #bac4c9;
        font-weight: 500;
        color: #333333;
        font-size: 12px;
        text-align: center;
      }
    }
    .remember {
      width: 315px;
      margin: 0 auto;
      display: flex;
      margin-top: 15px;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      align-items: center;
      .rem_left {
        display: flex;
        align-items: center;
        height: 15px;
        width: 100px;
        .rem_top {
          margin-right: 8px;
          width: 14px;
          height: 14px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }
        .rem_top2 {
          margin-right: 8px;
          width: 14px;
          height: 14px;
          border: 1px solid transparent;
          .intop {
            width: 100%;
            height: 100%;
          }
        }
        .rem_bottom {
          margin-top: 2px;
        }
      }
    }
  }
  </style>